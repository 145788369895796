import { initializeApp } from "firebase/app";

const firebaseConfig = {

    apiKey: "AIzaSyA7OFSVkJfke7EcBQq_sUhOdIc0usGMAPo",
  
    authDomain: "to-do-app-black.firebaseapp.com",
  
    projectId: "to-do-app-black",
  
    storageBucket: "to-do-app-black.appspot.com",
  
    messagingSenderId: "153274334078",
  
    appId: "1:153274334078:web:a5830c737edbe06d02512f",
  
    measurementId: "G-K7VM5QNQRK"
  
  };

export const app = initializeApp(firebaseConfig);