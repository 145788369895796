import './css/App.css';
import { Routes, Route, useNavigate  } from "react-router-dom";
// eslint-disable-next-line 
import { app } from './utils/firebase';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';

import Create from './pages/Create';
import Admin from './pages/Admin';
import NotFound from './pages/NotFound';
import Form from './components/Form';
import Home from './pages/Home';
import { useEffect, useState } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Single from './pages/Single';
import ShowByOwner from './pages/ShowByOwner';


function App() {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('')
  const [uid, setUid] = useState('')

  const createStyle={ position: 'absolute', left: '50%', top: '50%',transform: 'translate(-50%, -50%)' };

  useEffect(() => {
    let authToken = localStorage.getItem('Auth Token')
    
    if (authToken) {
        const cUser = getAuth();

        const interval = setInterval(() => {
          cUser?.currentUser?.getIdToken('forceRefresh')
            .then(function(idToken) {
                localStorage.removeItem('Bearer');
                localStorage.setItem('Bearer', idToken)
                console.log('check')
            }).catch(function(error) {
                console.log(error)
            });
        }, 300000);
        return () => clearInterval(interval);
    }
})

  useEffect(() => {
    let authToken = localStorage.getItem('Auth Token')

    if (authToken) {
      navigate('/admin')
    }
    // eslint-disable-next-line 
  }, [])

  const navigate = useNavigate();

  const handleAction = () => {
    const authentication = getAuth();
    signInWithEmailAndPassword(authentication, email, password)
        .then((response) => {
          setUid(response._tokenResponse.localId);
          navigate('/admin')
          localStorage.setItem('Auth Token', response._tokenResponse.refreshToken)
          localStorage.setItem('Bearer', response._tokenResponse.idToken)
          localStorage.setItem('uid', response._tokenResponse.localId)
        })
        .catch((error) => {
        
          if(error.code === 'auth/invalid-email'){
            toast.error('Gebruiker niet gevonden')
          }
          if(error.code === 'auth/internal-error'){
            toast.error('Er is een serverfout opgetreden, probeer het straks nog eens')
          }
          if(error.code === 'auth/wrong-password'){
            toast.error('Onjuist wachtwoord')
          }
        })
  }

  return (
      <>
        <div className="App">
          <div className="App-header">
            <Routes>
              <Route exact path="/" element={<Home/>}/>
              <Route exact path="/create/:user" element={<Create styles={createStyle}/>}/>
              <Route exact path="/todo/:todoID" element={<Single />}/>
              <Route exact path="/overzicht/:user/:owner" element={<ShowByOwner />}/>
              <Route exact path="/admin" element={<Admin uid={uid}/>}/>
              <Route exact path="/login" element={<Form setEmail={setEmail} setPassword={setPassword} handleAction={() => handleAction()} />}/>
              <Route path="*" element={<NotFound/>}/>
            </Routes>
          </div>
        </div>
        <ToastContainer />
      </>
  );
}

export default App;
