import { Paper, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import Items from '../components/item';

function ShowByOwner() {

    const [todos, setTodos] = useState([])
    const [isLoading, setIsLoading] = useState(false);

    const {user} = useParams();
    const {owner} = useParams();

    const getTodos = async (user, owner) => {
        setIsLoading(true);
        try {
            const res = await axios.get(`${process.env.REACT_APP_SERVER_BASEURL}/todos/${user}/${owner}`);
            setTodos(res.data);
            setIsLoading(false);
            } catch (error) {
            console.error(error);
            }
            
    }

    useEffect(() => {
        if(user?.length > 5){
            getTodos(user, owner);
        }
          // eslint-disable-next-line     
    }, [])

    if(isLoading) {
        return(
            <Paper style={{ padding: 25, position: 'absolute', left: '50%', top: '50%',transform: 'translate(-50%, -50%)' }}>
                <Typography  variant="h3">Laden </Typography>
                <Typography  variant="h6">Even geduld, we halen de gevens op.</Typography>
            </Paper>
        )
    }

    if(!isLoading & todos < 1){
        return(
            <div>          
                <Paper style={{ padding: 25, position: 'absolute', left: '50%', top: '50%',transform: 'translate(-50%, -50%)' }}>
                    <Typography  variant="h3">Lekker! </Typography>
                    <Typography  variant="h6">Al je verzoeken zijn afgerond!</Typography>
                </Paper>
            </div>
        )
    }

    return (
        <div>
            {
                todos?.sort(function(a,b) {
                    if(a.dateCreated < b.dateCreated) return -1;
                    if(a.dateCreated > b.dateCreated) return 1;
                    return 0;
                })?.map((item) =>{
                    return (
                        <Items key={item.id} data={item} setTodos={setTodos} todos={todos}/>
                    )
            })
            }
        </div>
    )
}

export default ShowByOwner
