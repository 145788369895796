import { Button, Container, Grid, Paper, Typography } from '@mui/material'
import axios from 'axios';
import React from 'react'

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

function Items({data, setTodos, todos}) {

    let authToken = localStorage.getItem('Bearer')

    const deleteTodo = async(id) => {
        axios.delete(`${process.env.REACT_APP_SERVER_BASEURL}/todos/delete/${id}`, {
            headers: {
                'Authorization': 'Bearer ' + authToken
            }
        })
        
        setTodos(todos.filter(function(item) { 
                return item.id !== id 
            }));
    }

    var datum = new Date(data.dateCreated*1000);

    return (
        <Container>
            <Paper style={{padding: 20, marginBottom: 10}} elevation={3} >
            <Grid
                container
                direction="column"
                alignItems="flex-start"
                >  
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignitems="flex-start"
                        >
                            <Grid item ><Typography variant="h4" gutterBottom >{capitalizeFirstLetter(data.aanvrager)}</Typography> </Grid>
                            {authToken && <Grid item >
                                <Button variant="outlined" onClick={() =>deleteTodo(data.id)} >X</Button>
                            </Grid> }
                    </Grid>
                    <Grid item ><Typography variant="subtitle2" >{datum.toLocaleString('nl-NL')}</Typography></Grid>
                    <Grid item ><Typography style={{textAlign: 'left'}} variant="body1" >{data.todo}</Typography></Grid>
            </Grid>
            </Paper>
        </Container>
    )
}

export default Items
