import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from './Button';
import { Container, Paper, Typography } from '@mui/material';
import { useNavigate } from 'react-router';

export default function BasicTextFields({ setPassword, setEmail, handleAction }) {

    let navigate = useNavigate();
    React.useEffect(() => {
        let authToken = localStorage.getItem('Auth Token')

        if (authToken) {
            navigate('/admin')
        }

        if (!authToken) {
            navigate('/login')
        }
        // eslint-disable-next-line
    }, [])

    return (
        <Container style={{ position: 'absolute', left: '50%', top: '50%',transform: 'translate(-50%, -50%)' }}>
            <Paper style={{padding: 20}}>
                <div>
                    <Typography variant="h4">
                        Inloggen
                    </Typography>
                </div>

                <Box
                    component="form"
                    sx={{
                        '& > :not(style)': { m: 1, width: '25ch' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <TextField id="email" label="Email" variant="outlined" onChange={(e) => setEmail(e.target.value)} />
                    <TextField id="password" type="password" label="Wachtwoord" variant="outlined" onChange={(e) => setPassword(e.target.value)} />
                </Box>
                <Button handleAction={handleAction} />
            </Paper>
        </Container>
    );
}