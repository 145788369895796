import { Button, Paper, Typography } from '@mui/material'
import React from 'react'
import {Link} from "react-router-dom";

function NotFound() {
    return (
        <div>
            <Paper style={{ padding: 25, position: 'absolute', left: '50%', top: '50%',transform: 'translate(-50%, -50%)' }}>
                <Typography variant="h3" gutterBottom >404</Typography>
                <Typography variant="h5" gutterBottom >Deze pagina bestaat niet (meer).</Typography>
                <Link to="/">
                    <Button variant="outlined">Terug naar de Homepage</Button>
                </Link>
            </Paper>
        </div>
    )
}

export default NotFound
