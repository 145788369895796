import * as React from 'react';
import {useParams} from "react-router-dom";
import Box from '@mui/material/Box';
import { LoadingButton } from '@mui/lab';
import TextField from '@mui/material/TextField';
import { Container, Paper, Typography } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Create({styles, adminID, setShowAdd}) {

    const [naam, setNaam] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [bericht, setBericht] = React.useState('');
    const [status, setStatus] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);
    
    const {user} = useParams();

    const userID = user ? user : adminID;

    const handleAction = async() => {
        setIsLoading(true);
        try {
            if(!naam){
                toast.error('Je (voor)naam is verplicht');
                setIsLoading(false);
            }
            if(!bericht){
                toast.error('Als je geen bericht achterlaat kan ik ook niets voor je doen');
                setIsLoading(false);
            }
            const res = await axios.post(`${process.env.REACT_APP_SERVER_BASEURL}/todos/create/${userID}`,{"aanvrager" : naam, "aanvragerMail": email, "todo": bericht});
            setStatus(res.data);
            setShowAdd(false);
            setIsLoading(false);
            } catch (error) {
                toast.error(error?.response?.data?.response);
                setIsLoading(false);
            }
    }

    return (
        <>
            <Container style={ styles }>
                <Paper style={{padding: 20}}>
                    
                    {!status ?  
                    <>
                        <div>
                            <Typography variant="h4">
                                Aanvraag
                            </Typography>
                        </div>

                        <Box
                            component="form"
                            sx={{
                                '& > :not(style)': { m: 1, width: '38%' },
                            }}
                            noValidate
                            autoComplete="off"
                        >   
                            <TextField id="naam" required label="Voornaam" variant="outlined" onChange={(e) => setNaam(e.target.value)} />
                            <TextField id="email" label="Email" variant="outlined" onChange={(e) => setEmail(e.target.value)} />
                        </Box>
                        <Box
                            component="form"
                            sx={{
                                '& > :not(style)': { m: 1, width: '80%' },
                            }}
                            noValidate
                            autoComplete="off"
                        >   
                            <TextField style={{height: '50px'}} id="text" required label="Bericht" variant="outlined" multiline maxRows={1} onChange={(e) => setBericht(e.target.value)} />
                            <LoadingButton loading={isLoading} fullWidth variant="contained" onClick={handleAction} >Versturen</LoadingButton>
                        </Box>
                    </>
                    :
                        <Typography variant="h5" > {status?.response} </Typography>
                    }
                    
                </Paper>
                
            </Container>
        </>
    )
}

export default Create
