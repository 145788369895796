import { Container, Grid, Paper, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';

function Single() {

    const {todoID} = useParams();
    const [todo, setTodo] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const getTodo = async () => {
        setIsLoading(true);
        try {
            const res = await axios.get(`${process.env.REACT_APP_SERVER_BASEURL}/todos/id/${todoID}`);
            setTodo(res.data);
            setIsLoading(false);
            } catch (error) {
            console.error(error);
            }
            
    }
    var datum = new Date(todo?.dateCreated?._seconds*1000);

    useEffect(() => {
        getTodo()
    //eslint-disable-next-line
    },[]);

    if(isLoading) {
        return(
            <Paper style={{ padding: 25, position: 'absolute', left: '50%', top: '50%',transform: 'translate(-50%, -50%)' }}>
                <Typography  variant="h3">Laden </Typography>
                <Typography  variant="h6">Even geduld, we halen de gevens op.</Typography>
            </Paper>
        )
    }

    return (
        <Container>
            <Paper style={{ padding: 25, position: 'absolute', left: '50%', top: '50%',transform: 'translate(-50%, -50%)' }} elevation={3} >
            <Grid
                container
                direction="column"
                alignItems="flex-start"
                >  
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignitems="flex-start"
                        >
                            <Grid item ><Typography variant="h4" gutterBottom >{todo.aanvrager}</Typography> </Grid>
                    </Grid>
                    <Grid item ><Typography variant="subtitle2" >{datum && datum.toLocaleString('nl-NL')}</Typography></Grid>
                    <Grid item ><Typography style={{textAlign: 'left'}} variant="body1" >{todo.todo}</Typography></Grid>
            </Grid>
            </Paper>
        </Container>
    )
}

export default Single
