import { Paper, Typography } from '@mui/material'
import React from 'react'
import {Link} from 'react-router-dom';

function Home() {
    return (
        <Paper style={{ padding: 25, position: 'absolute', left: '50%', top: '50%',transform: 'translate(-50%, -50%)' }}>
            <Typography variant="h3">Homepage</Typography>
            <Link to='/login'>
                <Typography variant="underline">Login</Typography>
            </Link>
        </Paper>
    )
}

export default Home
