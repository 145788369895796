import '../css/Admin.css';
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Items from '../components/item';
import { Button, Paper, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import Create from './Create';

function Admin() { 
    
    const handleLogout = () => {
        localStorage.removeItem('Bearer');
        localStorage.removeItem('uid');
        localStorage.removeItem('Auth Token');
        navigate('/login');
    }

    let uid = localStorage.getItem('uid')
    const createStyle={ marginBottom: '20px' };

    let navigate = useNavigate();
    const [showAdd, setShowAdd] = useState(false)
    const [todos, setTodos] = useState([])
    const [isLoading, setIsLoading] = useState(false);

    const getTodos = async (uid) => {
        setIsLoading(true);
        try {
            const res = await axios.get(`${process.env.REACT_APP_SERVER_BASEURL}/todos/${uid}`);
            setTodos(res.data);
            setIsLoading(false);
            } catch (error) {
            console.error(error);
            }
            
    }

    console.log('Laden: ' + isLoading);

    useEffect(() => {
        let authToken = localStorage.getItem('Auth Token')

        if (authToken) {
            navigate('/admin')
        }

        if (!authToken) {
            navigate('/login')
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        let uid = localStorage.getItem('uid')
        if(uid?.length > 5){
            getTodos(uid);
        }
        const interval = setInterval(() => {
            console.log('Update!');
            getTodos(uid);
          }, 10000);
          return () => clearInterval(interval);
          // eslint-disable-next-line     
    }, [])

    const handleShowAdd = () => {
        setShowAdd((s) => !s)
    }

    if(todos < 1){
        return(
            <div>
                {isLoading && <div style={{float: 'right', marginTop: '-25px'}}><div class="lds-facebook"><div></div><div></div><div></div></div></div>}
                <Button style={{marginBottom: 10, width: '300px'}} color="success"  onClick={handleShowAdd} variant="contained">{showAdd? 'Sluiten' : 'Handmatig toevoegen'}</Button>
                {showAdd 
                ? <Create styles={createStyle} adminID={uid} setShowAdd={setShowAdd} />
                : ""
                }
            
                <Paper style={{ padding: 25, position: 'absolute', left: '50%', top: '50%',transform: 'translate(-50%, -50%)' }}>
                    <Typography  variant="h3">Lekker! </Typography>
                    <Typography  variant="h6">Voeten kunnen omhoog, <br />want het werk voor vandaag is klaar!</Typography>
                    <Button variant="outlined" onClick={handleLogout}>Uitloggen</Button>
                </Paper>
            </div>
        )
    }

    return (
        <div>
            {isLoading && <div style={{float: 'right', marginTop: '-25px'}}><div class="lds-facebook"><div></div><div></div><div></div></div></div>}
            <Button style={{marginBottom: 10, width: '300px'}} color="success" onClick={handleShowAdd} variant="contained">{showAdd? 'Sluiten' : 'Handmatig toevoegen'}</Button>
                {showAdd 
                ? <Create styles={createStyle} adminID={uid} setShowAdd={setShowAdd} />
                : ""
                }
            {
                todos?.sort(function(a,b) {
                    if(a.dateCreated < b.dateCreated) return -1;
                    if(a.dateCreated > b.dateCreated) return 1;
                    return 0;
                })?.map((item) =>{
                    return (
                        <Items key={item.id} data={item} setTodos={setTodos} todos={todos}/>
                    )
            })
            }
            <Button variant="contained" onClick={handleLogout} color="error">Uitloggen</Button>
        </div>
    )
}

export default Admin
